<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Case Priority
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-flag-outline" prominent type="info">
          <div>
            <h3>Priorities add another dimension to UnStruct's OCO categorization and configurability:</h3>
            <ul>
              <li>
                <strong>Order of Address:</strong> Priority determines the sequence in which incidents or issues should be tackled.
              </li>
              <li>
                <strong>Determination Factors:</strong> It is often evaluated by considering the severity of the IAO, the importance of the affected system or service, and the urgency with which the issue needs to be resolved.
              </li>
              <li>
                <strong>High Priority:</strong> High priority issues are those that require immediate resolution.
              </li>
            </ul>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
          <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
            <v-card class="ma-4 elevation-2">
              <!-- Item Name and Status -->
              <v-card-title class="justify-space-between">
                  <v-icon large left>mdi-flag-outline</v-icon>
                  {{ item.name }}
                  <v-spacer></v-spacer>
                  <v-chip v-if="item.default" color="primary" dark class="mr-2">
                    <v-icon left>mdi-star</v-icon>
                    Default
                  </v-chip>
                  <v-chip v-if="item.enabled" color="success" dark class="mr-2">
                    <v-icon left>mdi-lightbulb-on-outline</v-icon>
                    Enabled
                  </v-chip>
                  <v-chip v-if="!item.enabled" dark class="mr-2">
                    <v-icon left>mdi-lightbulb-off-outline</v-icon>
                    Disabled
                  </v-chip>
              </v-card-title>

              <v-card-subtitle class="mb-2 mt-2">
                <v-chip v-if="item.sla" color="warning" dark class="mr-2">
                  <v-icon left>mdi-timer-sand</v-icon>
                  SLO: {{ item.sla }} hours
                </v-chip>
                <v-chip v-if="item.page_assignee" color="error" dark class="mr-2">
                  <v-icon left>mdi-account-group</v-icon>
                  Page Assignee
                </v-chip>
              </v-card-subtitle>

              <v-card-text>
                <div>
                  <v-icon left>mdi-axis-arrow-info</v-icon>
                  Description: {{ item.description }}
                </div>
                <div>
                  <v-icon left>mdi-sort-numeric-ascending</v-icon>
                  View Order: {{ item.view_order }}
                </div>
                <div>
                  <v-icon left>mdi-filter</v-icon>
                  Enabled: {{ item.enabled }}
                </div>
              </v-card-text>

              <!-- Actions -->
              <v-card-actions class="justify-end">
                <v-btn @click="createEditShow(item)" text color="primary">View / Edit</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import NewEditSheet from "@/case/priority/NewEditSheet.vue"
import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"

export default {
  name: "CasePriorityTable",

  components: {
    NewEditSheet,
    SettingsBreadcrumbs,
  },

  computed: {
    ...mapFields("case_priority", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query", "params"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("case_priority", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
